.App {
  text-align: center;
  margin: 2rem;
}

.section {
  text-align: left;
  max-width: 720px;
  margin: 4rem auto auto;
}
