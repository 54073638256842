@import url(https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
/** Slider **/
.leaflet-control-zoomslider-wrap {
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: #111;
	border-bottom: 1px solid #12ccc5;
}
.leaflet-control-zoomslider-body {
	width: 2px;
	border: solid #111;
	border-width: 0px 9px 0px 9px;
	background-color: #12ccc5;
	margin: 0 auto;
}
.leaflet-control-zoomslider-knob {
	position: relative;
	width: 12px;
	height: 4px;
	background-color: #12ccc5;
	border-radius: 2px;
	border: 1px solid #12ccc5;
	margin-left: -6px;
}

.leaflet-control-zoomslider-display {
	margin-top: 5px;
	width: 26px;
	height: 24px;
	border: 2px solid #12ccc5;
	background-color: #111;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #12ccc5;
	font-weight: bold;
	font-size: 1.5em;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
.leaflet-bar a, .leaflet-bar a:hover {
	border-bottom-color: #12ccc5;
}
.leaflet-control-zoomslider-body:hover {
	cursor: pointer;
}
.leaflet-control-zoomslider-knob:hover {
	cursor: default;
	cursor:    -moz-grab;
}

.leaflet-dragging .leaflet-control-zoomslider,
.leaflet-dragging .leaflet-control-zoomslider-wrap,
.leaflet-dragging .leaflet-control-zoomslider-body,
.leaflet-dragging .leaflet-control-zoomslider a,
.leaflet-dragging .leaflet-control-zoomslider a.leaflet-control-zoomslider-disabled,
.leaflet-dragging .leaflet-control-zoomslider-knob:hover  {
	cursor: move;
	cursor:    -moz-grabbing;
}

/** Leaflet Zoom Styles **/
.leaflet-container .leaflet-control-zoomslider {
	margin-left: 10px;
	margin-top: 10px;
}
.leaflet-control-zoomslider a {
	width: 26px;
	height: 26px;
	text-align: center;
	text-decoration: none;
	color: #12ccc5;
	display: block;
	background-color: #111;
}
.leaflet-control-zoomslider a:hover {
	background-color: #12ccc5;
	color: white;
}
.leaflet-control-zoomslider-in {
	font: bold 18px 'Lucida Console', Monaco, monospace;
}
.leaflet-control-zoomslider-in:after{
	content:"\002B"
}
.leaflet-control-zoomslider-out {
	font: bold 22px 'Lucida Console', Monaco, monospace;
}
.leaflet-control-zoomslider-out:after{
	content:"\2212"
}
.leaflet-control-zoomslider a.leaflet-control-zoomslider-disabled {
	cursor: default;
	color: #666;
}

/* Touch */
.leaflet-touch .leaflet-control-zoomslider-body {
	background-position: 10px 0px;
}
.leaflet-touch .leaflet-control-zoomslider-knob {
	width: 14px;
	margin-left: -7px;
}
.leaflet-touch .leaflet-control-zoomslider a {
	width: 30px;
	line-height: 30px;
}
.leaflet-touch .leaflet-control-zoomslider a:hover {
	width: 30px;
	line-height: 30px;
}
.leaflet-touch .leaflet-control-zoomslider-in {
	font-size: 24px;
	line-height: 29px;
}
.leaflet-touch .leaflet-control-zoomslider-out {
	font-size: 28px;
	line-height: 30px;
}
.leaflet-touch .leaflet-control-zoomslider {
	box-shadow: none;
	border: 4px solid rgba(0,0,0,0.3);
}

/* Old IE */

.leaflet-oldie .leaflet-control-zoomslider-wrap {
	width: 26px;
}

.leaflet-oldie .leaflet-control-zoomslider {
	border: 1px solid #12ccc5;
}


:root{
  --grad-green: #00f09e;
  --grad-green-mid: #03e9a5;
  --grad-mid: #08dfb0;
  --grad-blue-mid: #0cd7b9;
  --grad-blue: #12ccc5;
}

.loadingcontainer {
  text-align: center;
  width: 100%;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  background-color: #000;
  color: white;
}

polygon{
/* can set a static color for all shapes here */
/* fill: var(--grad-green) !important; */
}
.top {
  -webkit-animation: in-out 2000ms ease-in-out infinite;
          animation: in-out 2000ms ease-in-out infinite;
}
.right {
  -webkit-animation: in-out 2000ms ease-in-out infinite;
          animation: in-out 2000ms ease-in-out infinite;
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}
.bottom {
  -webkit-animation: in-out 2000ms ease-in-out infinite;
          animation: in-out 2000ms ease-in-out infinite;
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;
}
.left {
  -webkit-animation: in-out 2000ms ease-in-out infinite;
          animation: in-out 2000ms ease-in-out infinite;
  -webkit-animation-delay: 1500ms;
          animation-delay: 1500ms;
}

/* keyframing  */
@-webkit-keyframes in-out {
  0% { opacity: .3; fill: #00f09e; fill: var(--grad-green); }
  20% { opacity: .5; fill: #00f09e; fill: var(--grad-green); }
  40% { opacity: .8; fill: #08dfb0; fill: var(--grad-mid); }
  60% { opacity: .9; fill: #08dfb0; fill: var(--grad-mid); }
  80% { opacity: .8; fill: #12ccc5; fill: var(--grad-blue); }
  100% { opacity: .1; fill: #12ccc5; fill: var(--grad-blue); }
}
@keyframes in-out {
  0% { opacity: .3; fill: #00f09e; fill: var(--grad-green); }
  20% { opacity: .5; fill: #00f09e; fill: var(--grad-green); }
  40% { opacity: .8; fill: #08dfb0; fill: var(--grad-mid); }
  60% { opacity: .9; fill: #08dfb0; fill: var(--grad-mid); }
  80% { opacity: .8; fill: #12ccc5; fill: var(--grad-blue); }
  100% { opacity: .1; fill: #12ccc5; fill: var(--grad-blue); }
} 

.App {
  text-align: center;
  margin: 2rem;
}

.section {
  text-align: left;
  max-width: 720px;
  margin: 4rem auto auto;
}

@font-face {
  font-family: "Basement";
  src: url(/static/media/BasementGrotesque.1203e301.woff2) format("woff2");
}

body {
  margin: 0;
  font-family: 'Bai Jamjuree', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  overflow-x: hidden;
}

#map-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.map {
	height: 100%;
	width: 100%;
	position: relative;
}

.textogrande {
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: black;
}

button, div {
  font-family: 'Bai Jamjuree', sans-serif;
}


.leaflet-tooltip {
  color: white;
  background: radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11));
  font-size: 1.5em;
  opacity: 1;
}

.leaflet-popup {
  width: 250px
}
.leaflet-popup-content p {
  margin: 3px 0;
}
.MuiFormControlLabel-label {
  font-size: 1em !important;
  line-height: normal !important;
}
.rotate90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg)
}
.rotate-90 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg)
}
.rotate180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg)
}

.st0{fill:#e4761b;stroke:#e4761b}.st1{fill:#f6851b;stroke:#f6851b}.st2{fill:#763d16;stroke:#763d16}.st3{fill:#e2761b;stroke:#e2761b}.st4{fill:#c0ad9e;stroke:#c0ad9e}.st5{fill:#cd6116;stroke:#cd6116}.st6{fill:#e4751f;stroke:#e4751f}.st7{fill:#233447;stroke:#233447}.st8{fill:#d7c1b3;stroke:#d7c1b3}.st9{fill:#161616;stroke:#161616}

.leaflet-top.leaflet-right {
  display: none;
}

.div-icon {
  height: 70px !important;
  width: 200px !important;
  background-color: white;
  text-align: center;
}

img, video {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.divHtml {
  color: white;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.divContainer { 
  width: 100%;
  height: 100%;
  display: block;
}

.divText :first-child {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
}

.divContainer .divText {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.leaflet-container {
  background: #fff;
}  

::marker {
  color: transparent;
}

.MuiListItem-container {
  list-style-type: none;
}

.MuiBadge-badge {
  z-index: 300 !important;
}

.cellBadge {
  margin-top: 1px;
  margin-left: 5px;
  opacity: 0.5;
}
.cellBadge span {
  background-color: #00000060;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
}

.MuiAvatar-fallback {
  width: 90% !important;
  height: 90% !important;
}

.cellsText {
  font-family: "Dancing Script", cursive !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #24244d00; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8cfee5e; 
  border: 1px solid #1c103a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #12ccc5; 
  border: 1px solid #1c103a;
}

.leaflet-control-zoom a,
.leaflet-control-zoom a.leaflet-disabled {
  background: radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11));
  color: #12ccc5;
  transition: all 300ms ease-in;
}
.leaflet-control-zoom a:hover {
  background: radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11));
  color: #12ccc5;
}

.leaflet-control-layers-toggle {
  background-color: radial-gradient(at 50% 100%, rgba(0,0,0,1), rgb(11, 11, 11));
  color: white;
  transition: all 300ms ease-in;
  border-radius: 10px;
}

.leaflet-control-layers-toggle:hover {
  background-color: radial-gradient(at 50% 100%, rgba(0,0,0,1), rgb(11, 11, 11));
}

.leaflet-control-layers {
  color: white;
  text-transform: uppercase;
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 5px;
  background: radial-gradient(at 50% 100%, rgba(0,0,0,1), rgb(11, 11, 11));
  font-size: 1.2em;
  line-height: 2;
  font-weight: bold;
}

.leaflet-control-layers:hover {
  padding: 20px;
}

.leaflet-popup-content-wrapper {
  background: radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11));
  color: #fff;
  /*background: #5432a7; #24244d */
}
.leaflet-popup-tip {
  background-color: rgba(21, 21, 21, 1)
}
hr {
  opacity: 0.2;
}
.lista div {
  padding-top: 5px;
}

.color {background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);}

.leaflet-control-attribution {
  background-color: transparent !important;
}
.leaflet-control-attribution a {
  color: #666;
}

.rotating {
  animation: rotate 10s linear infinite;
  -webkit-animation: rotate 10s linear infinite;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

@keyframes rotate {
  from{
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
  }
  to{
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
  }
}
@-webkit-keyframes rotate {
  from{
      -webkit-transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
  }
  to{
      -webkit-transform: rotate(359deg);
      -webkit-transform-origin: 50% 50%;
  }
}

.rotatingcolor path {
  animation: color 20s linear infinite;
  -webkit-animation: color 20s linear infinite;
}

.shadowanim {
  animation: shadow 10s linear infinite;
  -webkit-animation: shadow 10s linear infinite;
}

.iconcolor path {
  animation: iconcolor 10s forwards ease-in-out infinite;
  -webkit-animation: iconcolor 10s forwards ease-in-out infinite;
  transform-origin: 100% 50%;
  -webkit-transform-origin: 100% 50%;
}

@keyframes color {
  0%{
    fill: rgba(255, 0, 0, 1);
  }
  10%{
    fill: rgba(255, 154, 0, 1);
  }  
  20%{
    fill: rgba(208, 222, 33, 1);
  }
  30%{
    fill: rgba(79, 220, 74, 1);
  }
  40%{
    fill: rgba(63, 218, 216, 1);
  }
  50%{
    fill: rgba(47, 201, 226, 1);
  }
  60%{
    fill: rgba(28, 127, 238, 1);
  }
  70%{
    fill: rgba(95, 21, 242, 1);
  }
  80%{
    fill: rgba(186, 12, 248, 1);
  }
  90%{
    fill: rgba(251, 7, 217, 1);
  }        
  100%{
    fill: rgba(255, 0, 0, 1);
  }
}
@-webkit-keyframes color {
  0%{
    fill: rgba(255, 0, 0, 1);
  }
  10%{
    fill: rgba(255, 154, 0, 1);
  }  
  20%{
    fill: rgba(208, 222, 33, 1);
  }
  30%{
    fill: rgba(79, 220, 74, 1);
  }
  40%{
    fill: rgba(63, 218, 216, 1);
  }
  50%{
    fill: rgba(47, 201, 226, 1);
  }
  60%{
    fill: rgba(28, 127, 238, 1);
  }
  70%{
    fill: rgba(95, 21, 242, 1);
  }
  80%{
    fill: rgba(186, 12, 248, 1);
  }
  90%{
    fill: rgba(251, 7, 217, 1);
  }        
  100%{
    fill: rgba(255, 0, 0, 1);
  }
}


@keyframes iconcolor {
  0%{
    fill: rgba(255, 0, 0, 1);
  }
  10%{
    fill: rgba(255, 154, 0, 1);
  }  
  20%{
    fill: rgba(208, 222, 33, 1);
  }
  30%{
    fill: rgba(79, 220, 74, 1);
  }
  40%{
    fill: rgba(63, 218, 216, 1);
  }
  50%{
    fill: rgba(47, 201, 226, 1);
  }
  60%{
    fill: rgba(28, 127, 238, 1);
  }
  70%{
    fill: rgba(95, 21, 242, 1);
  }
  80%{
    fill: rgba(186, 12, 248, 1);
  }
  90%{
    fill: rgba(251, 7, 217, 1);
  }        
  100%{
    fill: rgba(255, 0, 0, 1);
  }
}

@-webkit-keyframes iconcolor {
  0%{
    fill: rgba(255, 0, 0, 1);
  }
  10%{
    fill: rgba(255, 154, 0, 1);
  }  
  20%{
    fill: rgba(208, 222, 33, 1);
  }
  30%{
    fill: rgba(79, 220, 74, 1);
  }
  40%{
    fill: rgba(63, 218, 216, 1);
  }
  50%{
    fill: rgba(47, 201, 226, 1);
  }
  60%{
    fill: rgba(28, 127, 238, 1);
  }
  70%{
    fill: rgba(95, 21, 242, 1);
  }
  80%{
    fill: rgba(186, 12, 248, 1);
  }
  90%{
    fill: rgba(251, 7, 217, 1);
  }        
  100%{
    fill: rgba(255, 0, 0, 1);
  }
}


@keyframes shadow {
  0%{
    box-shadow: 0 0 5px #e2761b;
  }
  20%{
    box-shadow: 0 0 10px #74e21b;
  }
  40%{
    box-shadow: 0 0 10px #1be2b7;
  }
  60%{
    box-shadow: 0 0 10px #e21be2;
  }
  80%{
    box-shadow: 0 0 10px #e21b1b;
  }
  100%{
    box-shadow: 0 0 5px #e2761b;
  }
}
@-webkit-keyframes shadow {
  0%{
    box-shadow: 0 0 5px #e2761b;
  }
  20%{
    box-shadow: 0 0 10px #74e21b;
  }
  40%{
    box-shadow: 0 0 12px #1be2b7;
  }
  60%{
    box-shadow: 0 0 12px #e21be2;
  }
  80%{
    box-shadow: 0 0 10px #e21b1b;
  }
  100%{
    box-shadow: 0 0 5px #e2761b;
  }
}


model-viewer {
  width: 100%;
  --poster-color: transparent;
  background-color: transparent;
}
model-viewer#reveal {
  --poster-color: transparent;
}
model-viewer::part(default-progress-mask) {
  display: none;
}

model-viewer::part(default-progress-bar) {
  display: none;
}
model-viewer::part(default-poster) {
  width: 50% !important;
  -webkit-transform: translateX(50%) !important;
          transform: translateX(50%) !important;
}
/* model-viewer, model-viewer > * {
  --poster-color: transparent;
  background-color: transparent;
} */


@-webkit-keyframes shine {
  0% {
    background-position: right;    
  }
}


@keyframes shine {
  0% {
    background-position: right;    
  }
}

@-webkit-keyframes slide {
  0% {
    background-position: 50% 50%;    
  }
  25% {
    background-position: 0% 50%;    
  }
  50% {
    background-position: 50% 50%;    
  }
  75% {
    background-position: 100% 50%;    
  }  
}

@keyframes slide {
  0% {
    background-position: 50% 50%;    
  }
  25% {
    background-position: 0% 50%;    
  }
  50% {
    background-position: 50% 50%;    
  }
  75% {
    background-position: 100% 50%;    
  }  
}

@-webkit-keyframes slidevertical {
  0% {
    background-position: 50% 50%;    
  }
  25% {
    background-position: 50% 20%;    
  }
  50% {
    background-position: 50% 50%;    
  }
  75% {
    background-position: 50% 80%;    
  }  
}

@keyframes slidevertical {
  0% {
    background-position: 50% 50%;    
  }
  25% {
    background-position: 50% 20%;    
  }
  50% {
    background-position: 50% 50%;    
  }
  75% {
    background-position: 50% 80%;    
  }  
}

path.polygon {
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}
path.polygon:hover {
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;  
}
path.polygon.bordercolor {
  -webkit-animation: stroke 3s linear forwards infinite;
          animation: stroke 3s linear forwards infinite;
}
path.polygon.fillcoloranim {
  -webkit-animation: bg 3s linear forwards infinite;
          animation: bg 3s linear forwards infinite;
}
path.polygon.borderanim {
  -webkit-animation: offset 5s linear forwards infinite;
          animation: offset 5s linear forwards infinite;
}
path.polygon.borderanim.fillcoloranim {
  -webkit-animation: offset 5s linear forwards infinite, bg 3s linear forwards infinite;
          animation: offset 5s linear forwards infinite, bg 3s linear forwards infinite;
}
path.polygon.bordercolor.borderanim {
  -webkit-animation: stroke 3s linear forwards infinite, offset 5s linear forwards infinite;
          animation: stroke 3s linear forwards infinite, offset 5s linear forwards infinite;
}
path.polygon.bordercolor.fillcoloranim {
  -webkit-animation: stroke 3s linear forwards infinite, bg 3s linear forwards infinite;
          animation: stroke 3s linear forwards infinite, bg 3s linear forwards infinite;
}
path.polygon.bordercolor.borderanim.fillcoloranim {
  -webkit-animation: stroke 3s linear forwards infinite, bg 3s linear forwards infinite, offset 5s linear forwards infinite;
          animation: stroke 3s linear forwards infinite, bg 3s linear forwards infinite, offset 5s linear forwards infinite;
}


path.polygon.fillcoloranim2 {
  background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, 
  rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100% );  
  background-size: 200% auto;
  -webkit-animation: shine2 3s linear infinite;
          animation: shine2 3s linear infinite;
  background-position: 50% 50%;
}

path.polygon.dot {
  stroke-dashoffset: 120;
  stroke-dasharray: 1, 10;
}

path.polygon.snake {
  stroke-dashoffset: 300%;
  stroke-dasharray: 10%, 90%;
}

path.polygon.flash {
  stroke-dashoffset: 200%;
  stroke-dasharray: 1%, 50%;
}

path.polygon.dash {
  stroke-dashoffset: 120;
  stroke-dasharray: 10, 10;
}

path.polygon.scaleUp {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

path.polygon.glow-aqua {
  -webkit-filter: drop-shadow(0px 0px 5px aqua);
          filter: drop-shadow(0px 0px 5px aqua);
}
path.polygon.glow-lime {
  -webkit-filter: drop-shadow(0px 0px 5px lime);
          filter: drop-shadow(0px 0px 5px lime);
}
path.polygon.glow-yellow {
  -webkit-filter: drop-shadow(0px 0px 5px yellow);
          filter: drop-shadow(0px 0px 5px yellow);
}
path.polygon.glow-white {
  -webkit-filter: drop-shadow(0px 0px 5px white);
          filter: drop-shadow(0px 0px 5px white);
}
path.polygon.glow-red {
  -webkit-filter: drop-shadow(0px 0px 5px red);
          filter: drop-shadow(0px 0px 5px red);
}
path.polygon.glow-orangered {
  -webkit-filter: drop-shadow(0px 0px 5px orangered);
          filter: drop-shadow(0px 0px 5px orangered);
}
path.polygon.glow-hotpink {
  -webkit-filter: drop-shadow(0px 0px 5px hotpink);
          filter: drop-shadow(0px 0px 5px hotpink);
}
path.polygon.glow-blueviolet {
  -webkit-filter: drop-shadow(0px 0px 5px blueviolet);
          filter: drop-shadow(0px 0px 5px blueviolet);
}
path.polygon.glow-chromatic {
  -webkit-animation: chromaticglow 5s linear infinite;
          animation: chromaticglow 5s linear infinite;
}

path.polygon.animationdelay-1 {
  -webkit-animation-delay: 200ms !important;
          animation-delay: 200ms !important;
}
path.polygon.animationdelay-2 {
  -webkit-animation-delay: 400ms !important;
          animation-delay: 400ms !important;
}
path.polygon.animationdelay-3 {
  -webkit-animation-delay: 600ms !important;
          animation-delay: 600ms !important;
}
path.polygon.animationdelay-4 {
  -webkit-animation-delay: 800ms !important;
          animation-delay: 800ms !important;
}
path.polygon.animationdelay-5 {
  -webkit-animation-delay: 1000ms !important;
          animation-delay: 1000ms !important;
}
path.polygon.animationdelay-6 {
  -webkit-animation-delay: 1200ms !important;
          animation-delay: 1200ms !important;
}
path.polygon.animationdelay-7 {
  -webkit-animation-delay: 1400ms !important;
          animation-delay: 1400ms !important;
}
path.polygon.animationdelay-8 {
  -webkit-animation-delay: 1600ms !important;
          animation-delay: 1600ms !important;
}
path.polygon.animationdelay-9 {
  -webkit-animation-delay: 1800ms !important;
          animation-delay: 1800ms !important;
}
path.polygon.animationdelay-10 {
  -webkit-animation-delay: 2s !important;
          animation-delay: 2s !important;
}

@-webkit-keyframes offset {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes offset {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes stroke {
 0%{
   stroke: rgba(255, 0, 0, 1);
 }
 10%{
   stroke: rgba(255, 154, 0, 1);
 }  
 20%{
   stroke: rgba(208, 222, 33, 1);
 }
 30%{
   stroke: rgba(79, 220, 74, 1);
 }
 40%{
   stroke: rgba(63, 218, 216, 1);
 }
 50%{
   stroke: rgba(47, 201, 226, 1);
 }
 60%{
   stroke: rgba(28, 127, 238, 1);
 }
 70%{
   stroke: rgba(95, 21, 242, 1);
 }
 80%{
   stroke: rgba(186, 12, 248, 1);
 }
 90%{
   stroke: rgba(251, 7, 217, 1);
 }        
 100%{
   stroke: rgba(255, 0, 0, 1);
 }
}

@keyframes stroke {
 0%{
   stroke: rgba(255, 0, 0, 1);
 }
 10%{
   stroke: rgba(255, 154, 0, 1);
 }  
 20%{
   stroke: rgba(208, 222, 33, 1);
 }
 30%{
   stroke: rgba(79, 220, 74, 1);
 }
 40%{
   stroke: rgba(63, 218, 216, 1);
 }
 50%{
   stroke: rgba(47, 201, 226, 1);
 }
 60%{
   stroke: rgba(28, 127, 238, 1);
 }
 70%{
   stroke: rgba(95, 21, 242, 1);
 }
 80%{
   stroke: rgba(186, 12, 248, 1);
 }
 90%{
   stroke: rgba(251, 7, 217, 1);
 }        
 100%{
   stroke: rgba(255, 0, 0, 1);
 }
}


@-webkit-keyframes bg {
  0%{
    fill: rgba(255, 0, 0, 1);
  }
  10%{
    fill: rgba(255, 154, 0, 1);
  }  
  20%{
    fill: rgba(208, 222, 33, 1);
  }
  30%{
    fill: rgba(79, 220, 74, 1);
  }
  40%{
    fill: rgba(63, 218, 216, 1);
  }
  50%{
    fill: rgba(47, 201, 226, 1);
  }
  60%{
    fill: rgba(28, 127, 238, 1);
  }
  70%{
    fill: rgba(95, 21, 242, 1);
  }
  80%{
    fill: rgba(186, 12, 248, 1);
  }
  90%{
    fill: rgba(251, 7, 217, 1);
  }        
  100%{
    fill: rgba(255, 0, 0, 1);
  }
}


@keyframes bg {
  0%{
    fill: rgba(255, 0, 0, 1);
  }
  10%{
    fill: rgba(255, 154, 0, 1);
  }  
  20%{
    fill: rgba(208, 222, 33, 1);
  }
  30%{
    fill: rgba(79, 220, 74, 1);
  }
  40%{
    fill: rgba(63, 218, 216, 1);
  }
  50%{
    fill: rgba(47, 201, 226, 1);
  }
  60%{
    fill: rgba(28, 127, 238, 1);
  }
  70%{
    fill: rgba(95, 21, 242, 1);
  }
  80%{
    fill: rgba(186, 12, 248, 1);
  }
  90%{
    fill: rgba(251, 7, 217, 1);
  }        
  100%{
    fill: rgba(255, 0, 0, 1);
  }
}

.MuiTooltip-tooltip {
  font-size: .8em !important;
  background: black;
  color: #12ccc5;
}

.verified-badge {
  position: absolute;
  right: 0;
  top: 1%;
  width: 12%;
  height: auto !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  -webkit-filter: drop-shadow(0px 0px 1ch rgba(255,255,255,0.5));
          filter: drop-shadow(0px 0px 1ch rgba(255,255,255,0.5));
}
.verified-badge.profile {
  padding: 0;
  background-color: transparent;
  opacity: .8;
  width: 40px;
  display: flex;
  align-items: center;
  right: 30px;
  font-size: 1.5em;
  height: auto;
  -webkit-filter: none;
          filter: none;
}
.verified-badge.profile span {
  padding-right: 5px;
}

.verified-badge img {
  width: 100%;
  height: 100%;
}

.MuiTab-textColorSecondary {
  color: white !important;
}
.MuiTab-textColorSecondary.Mui-selected {
  color: #12ccc5 !important;
}
.MuiTabs-indicator {
  /* width: 25% !important; */
  background-color: #12ccc5 !important;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: 0;
}

.menuText {
  font-size: 1.2em !important;
  font-weight: bold !important;
}

.menuText:hover {
  color: #12ccc5 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

#country .MuiTypography-body1 {
  color: white !important;
  font-size: 0.9em !important;
}

.MuiAccordionDetails-root .MuiInputBase-root svg {
  fill: #FFF !important;
  stroke: #FFF !important;
}

.rainbowtext {
  background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, 
  rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100% );
  background-size: 200% auto;
  
  color: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  -webkit-animation: shine2 6s linear infinite;
  
          animation: shine2 6s linear infinite;
}

@-webkit-keyframes shine2 {
  to {
    background-position: 200% center ;
  }
}

@keyframes shine2 {
  to {
    background-position: 200% center ;
  }
}


.rainbowtext2 {
  background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, 
  rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100% );
  
  color: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.raretext {
  color: #12ccc5;
}
button.MuiButton-containedPrimary {
  color: #fff;
  background-color: #057773;
}
button.MuiButton-containedPrimary:hover {
  color: #fff;
  background-color: #12ccc5;
}

.MuiButton-contained.Mui-disabled .MuiButton-label {
  cursor: not-allowed !important;
  color: #222;
}

.socials {
  margin: 0;
  padding: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.socials img {
  max-width: 30px;
  opacity: 0.5;
  transition: opacity 400ms ease-in-out;
  cursor: pointer;
}
.socials img:hover {
  opacity: 1;
}

.buttoncontainer {
  margin: 20px;
  padding: 30px;
  border-radius: 20px;
  background: rgba(0,0,0,0.3);
}

.changecolor {
  -webkit-animation: textcolor 3s linear infinite;
          animation: textcolor 3s linear infinite;
}

.loading {
  font-weight: bold;
  font-size: 50px;
  background-color: "rgba(0,0,0,0.3)";
  letter-spacing: 10px;
}

@keyframes textcolor {
  0%{
    color: rgba(255, 0, 0, 1);
  }
  10%{
    color: rgba(255, 154, 0, 1);
  }  
  20%{
    color: rgba(208, 222, 33, 1);
  }
  30%{
    color: rgba(79, 220, 74, 1);
  }
  40%{
    color: rgba(63, 218, 216, 1);
  }
  50%{
    color: rgba(47, 201, 226, 1);
  }
  60%{
    color: rgba(28, 127, 238, 1);
  }
  70%{
    color: rgba(95, 21, 242, 1);
  }
  80%{
    color: rgba(186, 12, 248, 1);
  }
  90%{
    color: rgba(251, 7, 217, 1);
  }        
  100%{
    color: rgba(255, 0, 0, 1);
  }
}
@-webkit-keyframes textcolor {
  0%{
    color: rgba(255, 0, 0, 1);
  }
  10%{
    color: rgba(255, 154, 0, 1);
  }  
  20%{
    color: rgba(208, 222, 33, 1);
  }
  30%{
    color: rgba(79, 220, 74, 1);
  }
  40%{
    color: rgba(63, 218, 216, 1);
  }
  50%{
    color: rgba(47, 201, 226, 1);
  }
  60%{
    color: rgba(28, 127, 238, 1);
  }
  70%{
    color: rgba(95, 21, 242, 1);
  }
  80%{
    color: rgba(186, 12, 248, 1);
  }
  90%{
    color: rgba(251, 7, 217, 1);
  }        
  100%{
    color: rgba(255, 0, 0, 1);
  }
}

@keyframes bgcolor {
  0%{
    background-color: rgba(255, 0, 0, 1);
  }
  10%{
    background-color: rgba(255, 154, 0, 1);
  }  
  20%{
    background-color: rgba(208, 222, 33, 1);
  }
  30%{
    background-color: rgba(79, 220, 74, 1);
  }
  40%{
    background-color: rgba(63, 218, 216, 1);
  }
  50%{
    background-color: rgba(47, 201, 226, 1);
  }
  60%{
    background-color: rgba(28, 127, 238, 1);
  }
  70%{
    background-color: rgba(95, 21, 242, 1);
  }
  80%{
    background-color: rgba(186, 12, 248, 1);
  }
  90%{
    background-color: rgba(251, 7, 217, 1);
  }        
  100%{
    background-color: rgba(255, 0, 0, 1);
  }
}
@-webkit-keyframes bgcolor {
  0%{
    background-color: rgba(255, 0, 0, 1);
  }
  10%{
    background-color: rgba(255, 154, 0, 1);
  }  
  20%{
    background-color: rgba(208, 222, 33, 1);
  }
  30%{
    background-color: rgba(79, 220, 74, 1);
  }
  40%{
    background-color: rgba(63, 218, 216, 1);
  }
  50%{
    background-color: rgba(47, 201, 226, 1);
  }
  60%{
    background-color: rgba(28, 127, 238, 1);
  }
  70%{
    background-color: rgba(95, 21, 242, 1);
  }
  80%{
    background-color: rgba(186, 12, 248, 1);
  }
  90%{
    background-color: rgba(251, 7, 217, 1);
  }        
  100%{
    background-color: rgba(255, 0, 0, 1);
  }
}

.emoji {
  font-family: Segoe UI Emoji;
  color: white !important;
  opacity: 1;
  font-size: 20px;
}

.leaflet-overlay-pane {
  z-index: 600;
}

path.polygon.zone-collection:hover {
  -webkit-animation: boxshadow 5s linear infinite;
          animation: boxshadow 5s linear infinite;
  opacity: 1;
  fill: rgba(0,0,0,1);
}

path.polygon.zone-collection.zone-white:hover {
  -webkit-animation: boxshadow 5s linear infinite;
          animation: boxshadow 5s linear infinite;
  opacity: 1;
  fill: rgba(255,255,255,1);
}

path.polygon.zone-collection.zone-incognito {
  -webkit-animation: bg 5s linear forwards infinite;
          animation: bg 5s linear forwards infinite;
  opacity: .5;
}
path.polygon.zone-collection.zone-incognito:hover {
  -webkit-animation: bg 10s linear forwards infinite, boxshadow 5s linear infinite;
          animation: bg 10s linear forwards infinite, boxshadow 5s linear infinite;
  opacity: 1;
}


.zone-elemental {
  opacity: 1;
  fill: rgba(33,33,33,1);
  stroke-width: 2px;
}
.zone-elemental.fire {
  stroke: orange;
}
.zone-elemental.water {
  stroke: aqua;
}
.zone-elemental.air {
  stroke: white;
}
.zone-elemental.earth {
  stroke: brown;
}
.zone-elemental.fire:hover {
  -webkit-filter: drop-shadow(0 0 20px orange) !important;
          filter: drop-shadow(0 0 20px orange) !important;
}
.zone-elemental.water:hover {
  -webkit-filter: drop-shadow(0 0 20px aqua) !important;
          filter: drop-shadow(0 0 20px aqua) !important;
}
.zone-elemental.air:hover {
  -webkit-filter: drop-shadow(0 0 20px white) !important;
          filter: drop-shadow(0 0 20px white) !important;
}
.zone-elemental.earth:hover {
  -webkit-filter: drop-shadow(0 0 20px brown) !important;
          filter: drop-shadow(0 0 20px brown) !important;
}
.zone-collection {  
  fill: rgba(33,33,33,1);
  stroke: url(#rainbow);
  opacity: 1;
  stroke-width: 2px;
}

.zone-collection.zone-white {  
  fill: rgba(255,255,255,1);
  stroke: url(#rainbow);
  opacity: 1;
  stroke-width: 2px;
}


.zone-wanderers {
  fill: rgba(255,255,255,0);
  stroke: url(#rainbow);
  stroke-width: 2px;
}

.zone-wanderers:hover {
  fill: rgba(255,255,255,0) !important;
  stroke: url(#rainbow);
  stroke-width: 2px;
}

.zonepopup {
  z-index: 600;
  border-radius: 15px;
  padding: 10px 20px;
  border: 1px solid #12ccc5;
}
.zonepopup:before {
  border-top-color: #12ccc5;
}
.zoneimage {
  border-radius: 50%;
  z-index: 300 !important;
}

.zoneimage-square {
  border-radius: 0% !important;
  z-index: 300 !important;
}

img.zone-collection.zone-incognito {
  position: relative !important;
  left: -1ch !important;
}

.collectionsocials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 0;
}

path.polygon.bordercolor2 {
  -webkit-animation: zonestroke 3s linear forwards infinite;
          animation: zonestroke 3s linear forwards infinite;
  opacity: .3;
}

path.polygon.bordercolor2:hover {
  -webkit-animation: zonestroke 3s linear forwards infinite;
          animation: zonestroke 3s linear forwards infinite;
  opacity: 1;
  
}

.zonetext-reserved {
  color: silver;
}
.zonetext-air, .zonetext-earth, .zonetext-fire, .zonetext-water {
  -webkit-animation: shine2 6s linear infinite;
          animation: shine2 6s linear infinite;
}

.zonetext-fire {
  background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 33%, rgba(208, 222, 33, 1) 66%, rgba(255, 0, 0, 1) 100% );
  background-size: 200% auto !important;
  background-clip: text !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  
}

.zonetext-water {
  background: linear-gradient(90deg, rgb(2, 48, 90) 0%, rgb(0, 132, 255) 33%, rgb(0, 255, 234) 66%, rgb(0, 132, 255) 100% );
  background-size: 200% auto !important;
  background-clip: text !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  
}

.zonetext-air {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(83, 83, 83) 33%, rgb(117, 117, 117) 66%, rgb(255, 255, 255) 100% );
  background-size: 200% auto !important;
  background-clip: text !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  
}

.zonetext-earth {
  background: linear-gradient(90deg, rgb(90, 37, 2) 0%, rgb(194, 103, 60) 33%, rgb(241, 181, 68) 66%, rgb(90, 37, 2) 100% );
  background-size: 200% auto !important;
  background-clip: text !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  
}

@-webkit-keyframes zonestroke {
  0%{
    stroke: rgba(255, 0, 0, 1);
  }
  10%{
    stroke: rgba(255, 154, 0, 1);
  }  
  20%{
    stroke: rgba(208, 222, 33, 1);
  }
  30%{
    stroke: rgba(79, 220, 74, 1);
  }
  40%{
    stroke: rgba(63, 218, 216, 1);
  }
  50%{
    stroke: rgba(47, 201, 226, 1);
  }
  60%{
    stroke: rgba(28, 127, 238, 1);
  }
  70%{
    stroke: rgba(95, 21, 242, 1);
  }
  80%{
    stroke: rgba(186, 12, 248, 1);
  }
  90%{
    stroke: rgba(251, 7, 217, 1);
  }        
  100%{
    stroke: rgba(255, 0, 0, 1);
  }
 }

@keyframes zonestroke {
  0%{
    stroke: rgba(255, 0, 0, 1);
  }
  10%{
    stroke: rgba(255, 154, 0, 1);
  }  
  20%{
    stroke: rgba(208, 222, 33, 1);
  }
  30%{
    stroke: rgba(79, 220, 74, 1);
  }
  40%{
    stroke: rgba(63, 218, 216, 1);
  }
  50%{
    stroke: rgba(47, 201, 226, 1);
  }
  60%{
    stroke: rgba(28, 127, 238, 1);
  }
  70%{
    stroke: rgba(95, 21, 242, 1);
  }
  80%{
    stroke: rgba(186, 12, 248, 1);
  }
  90%{
    stroke: rgba(251, 7, 217, 1);
  }        
  100%{
    stroke: rgba(255, 0, 0, 1);
  }
 }
 

 
@keyframes boxshadow {
  0%{
    -webkit-filter: drop-shadow(0 0 20px #e2761b);
            filter: drop-shadow(0 0 20px #e2761b);
  }
  20%{
    -webkit-filter: drop-shadow(0 0 20px #74e21b);
            filter: drop-shadow(0 0 20px #74e21b);
  }
  40%{
    -webkit-filter: drop-shadow(0 0 20px #1be2b7);
            filter: drop-shadow(0 0 20px #1be2b7);
  }
  60%{
    -webkit-filter: drop-shadow(0 0 20px #e21be2);
            filter: drop-shadow(0 0 20px #e21be2);
  }
  80%{
    -webkit-filter: drop-shadow(0 0 20px #e21b1b);
            filter: drop-shadow(0 0 20px #e21b1b);
  }
  100%{
    -webkit-filter: drop-shadow(0 0 20px #e2761b);
            filter: drop-shadow(0 0 20px #e2761b);
  }
}
@-webkit-keyframes boxshadow {
  0%{
    -webkit-filter: drop-shadow(0 0 20px #e2761b);
            filter: drop-shadow(0 0 20px #e2761b);
  }
  20%{
    -webkit-filter: drop-shadow(0 0 20px #74e21b);
            filter: drop-shadow(0 0 20px #74e21b);
  }
  40%{
    -webkit-filter: drop-shadow(0 0 20px #1be2b7);
            filter: drop-shadow(0 0 20px #1be2b7);
  }
  60%{
    -webkit-filter: drop-shadow(0 0 20px #e21be2);
            filter: drop-shadow(0 0 20px #e21be2);
  }
  80%{
    -webkit-filter: drop-shadow(0 0 20px #e21b1b);
            filter: drop-shadow(0 0 20px #e21b1b);
  }
  100%{
    -webkit-filter: drop-shadow(0 0 20px #e2761b);
            filter: drop-shadow(0 0 20px #e2761b);
  }
}


@keyframes boxshadow2 {
  0%{
    -webkit-filter: drop-shadow(0 0 20px #aaa);
            filter: drop-shadow(0 0 20px #aaa);
  }
  20%{
    -webkit-filter: drop-shadow(0 0 25px #aaa);
            filter: drop-shadow(0 0 25px #aaa);
  }
  40%{
    -webkit-filter: drop-shadow(0 0 30px #aaa);
            filter: drop-shadow(0 0 30px #aaa);
  }
  60%{
    -webkit-filter: drop-shadow(0 0 30px #aaa);
            filter: drop-shadow(0 0 30px #aaa);
  }
  80%{
    -webkit-filter: drop-shadow(0 0 25px #aaa);
            filter: drop-shadow(0 0 25px #aaa);
  }
  100%{
    -webkit-filter: drop-shadow(0 0 20px #aaa);
            filter: drop-shadow(0 0 20px #aaa);
  }
}
@-webkit-keyframes boxshadow2 {
  0%{
    -webkit-filter: drop-shadow(0 0 20px #aaa);
            filter: drop-shadow(0 0 20px #aaa);
  }
  20%{
    -webkit-filter: drop-shadow(0 0 25px #aaa);
            filter: drop-shadow(0 0 25px #aaa);
  }
  40%{
    -webkit-filter: drop-shadow(0 0 30px #aaa);
            filter: drop-shadow(0 0 30px #aaa);
  }
  60%{
    -webkit-filter: drop-shadow(0 0 30px #aaa);
            filter: drop-shadow(0 0 30px #aaa);
  }
  80%{
    -webkit-filter: drop-shadow(0 0 25px #aaa);
            filter: drop-shadow(0 0 25px #aaa);
  }
  100%{
    -webkit-filter: drop-shadow(0 0 20px #aaa);
            filter: drop-shadow(0 0 20px #aaa);
  }
}

@keyframes chromaticglow {
  0%{
    -webkit-filter: drop-shadow(0 0 5px #e2761b);
            filter: drop-shadow(0 0 5px #e2761b);
  }
  20%{
    -webkit-filter: drop-shadow(0 0 5px #74e21b);
            filter: drop-shadow(0 0 5px #74e21b);
  }
  40%{
    -webkit-filter: drop-shadow(0 0 5px #1be2b7);
            filter: drop-shadow(0 0 5px #1be2b7);
  }
  60%{
    -webkit-filter: drop-shadow(0 0 5px #e21be2);
            filter: drop-shadow(0 0 5px #e21be2);
  }
  80%{
    -webkit-filter: drop-shadow(0 0 5px #e21b1b);
            filter: drop-shadow(0 0 5px #e21b1b);
  }
  100%{
    -webkit-filter: drop-shadow(0 0 5px #e2761b);
            filter: drop-shadow(0 0 5px #e2761b);
  }
}
@-webkit-keyframes chromaticglow {
  0%{
    -webkit-filter: drop-shadow(0 0 5px #e2761b);
            filter: drop-shadow(0 0 5px #e2761b);
  }
  20%{
    -webkit-filter: drop-shadow(0 0 5px #74e21b);
            filter: drop-shadow(0 0 5px #74e21b);
  }
  40%{
    -webkit-filter: drop-shadow(0 0 5px #1be2b7);
            filter: drop-shadow(0 0 5px #1be2b7);
  }
  60%{
    -webkit-filter: drop-shadow(0 0 5px #e21be2);
            filter: drop-shadow(0 0 5px #e21be2);
  }
  80%{
    -webkit-filter: drop-shadow(0 0 5px #e21b1b);
            filter: drop-shadow(0 0 5px #e21b1b);
  }
  100%{
    -webkit-filter: drop-shadow(0 0 5px #e2761b);
            filter: drop-shadow(0 0 5px #e2761b);
  }
}

.sea_logo {
  -webkit-animation: boxshadow 5s linear infinite;
          animation: boxshadow 5s linear infinite;
}

.celda_logo {
  -webkit-animation: boxshadow2 30s linear infinite;
          animation: boxshadow2 30s linear infinite;
}

.delay-300 {
  --animation-delay: 300ms;
}
.delay-600 {
  --animation-delay: 600ms;
}

.MuiPopover-root .MuiList-root {
  color: white;
  background-color: #333;
}
.MuiInputAdornment-root p {
  color: white;
}
.MuiSelect-iconOutlined {
  color: white !important;
}

.zoomdisplay {
  font-size: 10em;
}

.MuiPopover-root .MuiList-root  {
  background: radial-gradient(at 50% 100%, rgba(22,22,22,1), rgb(11, 11, 11));
}

.MuiPopover-root .MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: #12ccc5 !important;
}

.MuiPaper-rounded {
  border-radius: 30px;
}
.battlesLogo, .battlecard {
  transition: all 200ms linear;
}
.battlesLogo:hover {
  -webkit-transform: scale(1.2) rotate(10deg);
          transform: scale(1.2) rotate(10deg);
  -webkit-filter: drop-shadow(0 0 10px red);
          filter: drop-shadow(0 0 10px red);
}
.battlecard:hover {
  -webkit-transform: scale(2);
          transform: scale(2);
  z-index: 1000;
}
