
:root{
  --grad-green: #00f09e;
  --grad-green-mid: #03e9a5;
  --grad-mid: #08dfb0;
  --grad-blue-mid: #0cd7b9;
  --grad-blue: #12ccc5;
}

.loadingcontainer {
  text-align: center;
  width: 100%;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  background-color: #000;
  color: white;
}

polygon{
/* can set a static color for all shapes here */
/* fill: var(--grad-green) !important; */
}
.top {
  animation: in-out 2000ms ease-in-out infinite;
}
.right {
  animation: in-out 2000ms ease-in-out infinite;
  animation-delay: 500ms;
}
.bottom {
  animation: in-out 2000ms ease-in-out infinite;
  animation-delay: 1000ms;
}
.left {
  animation: in-out 2000ms ease-in-out infinite;
  animation-delay: 1500ms;
}

/* keyframing  */
@keyframes in-out {
  0% { opacity: .3; fill: var(--grad-green); }
  20% { opacity: .5; fill: var(--grad-green); }
  40% { opacity: .8; fill: var(--grad-mid); }
  60% { opacity: .9; fill: var(--grad-mid); }
  80% { opacity: .8; fill: var(--grad-blue); }
  100% { opacity: .1; fill: var(--grad-blue); }
} 
